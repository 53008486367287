import React from 'react';
import { t } from 'i18next';
import { Input, Table } from 'reactstrap';
import { TableHead } from '../../table';
import { ButtonActionCustom } from '../../buttons';
import Select from 'react-select';

class MaterialField extends React.Component {
    state = {
        data: this.props.field.data ? this.props.field.data : []
    };

    componentDidMount() {
        this.handleScan(false);
    }

    render() {
        return (
            <Table size="sm">
                <TableHead>
                    <tr>
                        <th colSpan={2}>&nbsp;</th>
                        <th className='text-right'>
                            <ButtonActionCustom size="xs" color="success" icon="plus" tooltip={t('common:Add')} onClick={() => this.handOptionAddClick(true)} />
                        </th>
                    </tr>
                </TableHead>
                <tbody cols={3} className="material-body">
                    {this.state.data.map((record, index) => (
                        <tr key={index}>
                            <td>
                                <Input type="text" name={'option-' + index} id={'option-' + index} value={record.code} onChange={e => this.handleOptionChange(index, 'code', e.target.value)} />
                            </td>
                            <td>
                                <Select id={this.props.field.name + '-select'} name={this.props.field.name + '-select'} className="form-select"
                                    isClearable simpleValue
                                    value={this.selectedOption(record.option)}
                                    options={this.options()}
                                    onChange={e => this.handleOptionChange(index, 'option', e.value)}
                                />
                            </td>
                            <td style={{ width: '5%' }} className='text-right'>
                                <ButtonActionCustom id={'remove' + this.props.field.name + index} size="xs" color="danger" icon="trash" tooltip={t('common:Remove')} onClick={() => this.handOptionRemoveClick(index)} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    }

    options = () => Object.values(this.props.field.options.choices).map(choice => {
        return { value: choice, label: choice }
    });

    selectedOption = (value) => {
        let options = this.options();

        let selected = null;
        if (value) selected = options.filter(option => option.value === value)[0];

        return selected ? selected : options[0];
    }

    handOptionAddClick = (focus = false) => {
        let data = this.state.data;
        data.push({ code: null, option: this.selectedOption().value });
        this.setState({ data: data }, () => {
            if (focus) {
                this.focusInput()
            }
        });
    }

    handOptionRemoveClick = (index) => {
        let data = this.state.data;
        data.splice(index, 1);
        this.setState({ data: data }, () => this.props.onChangeValue(this.props.field.name, data));
    }

    handleOptionChange = (index, key, value) => {
        let data = this.state.data;
        data[index][key] = value;
        this.setState({ data: data }, () => {
            this.props.onChangeValue(this.props.field.name, data);
            this.handleScan();
        });
    }

    _timer = null;
    handleScan = (focus = true) => {
        if (this._timer) clearTimeout(this._timer);
        this._timer = setTimeout(() => {
            let lastRow = document.querySelector('.material-body tr:last-child');
            if (!lastRow || !!lastRow.querySelector('input[name^="option-"]').value) {
                this.handOptionAddClick();
            }

            if (focus) this.focusInput();
        }, 250);
    }

    focusInput = () => {
        let lastInput = document.querySelector('.material-body tr:last-child input[name^="option-"');
        if (lastInput) lastInput.focus();
    }
}

export default MaterialField;